import {useReactiveVar} from '@apollo/client'
import {useTranslation} from 'react-i18next'
import {useUserQuery} from 'src/generated/graphql'
import {isLoggedInVar, userDataVar} from 'src/graphql/apollo'

const useUserLanguage = () => {
  const {i18n} = useTranslation()
  const userData = useReactiveVar(userDataVar)
  const isLoggedIn = useReactiveVar(isLoggedInVar)

  useUserQuery({
    skip: isLoggedIn === null,
    context: {
      headers: {
        'X-Parse-Session-Token': localStorage.getItem('parseToken'),
      },
    },
    variables: {
      id: userData?.user?.objectId,
    },

    onCompleted: data => {
      if (!data?.user?.locale) {
        return
      }

      i18n.changeLanguage(data.user.locale)
    },
  })
}

export default useUserLanguage
