import {useReactiveVar} from '@apollo/client'
import SelectBox, {onSelectChange} from '@components/atoms/SelectBox'
import {FALLBACK_LNG, LANGUAGE_TYPE} from '@constants/i18n'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useUpdateUserMutation} from 'src/generated/graphql'
import {userDataVar} from 'src/graphql/apollo'

const LANGUAGE_ITEMS: {value: LANGUAGE_TYPE; label: string}[] = [
  {value: 'ko', label: 'KR'},
  {value: 'ja', label: 'JA'},
  {value: 'en', label: 'EN'},
  {value: 'es', label: 'ES'},
]

interface LanguageSelectProps {
  defaultListboxOpen?: boolean
}

const LanguageSelectOrganizer = ({defaultListboxOpen}: LanguageSelectProps) => {
  const {i18n, t} = useTranslation()
  const userData = useReactiveVar(userDataVar)
  const [updateUser] = useUpdateUserMutation()

  const handleSelectedLanguage: onSelectChange<LANGUAGE_TYPE> = (
    event,
    value: LANGUAGE_TYPE | null,
  ) => {
    const nextLanguage = value ?? FALLBACK_LNG

    i18n.changeLanguage(nextLanguage)

    if (!userData) {
      return
    }

    updateUser({
      context: {
        headers: {
          'X-Parse-Session-Token': localStorage.getItem('parseToken'),
        },
      },
      variables: {
        input: {
          id: userData.user.objectId,
          fields: {
            locale: nextLanguage,
          },
        },
      },

      onError: () => {
        alert(t('errorPage.error'))
      },
    })
  }

  return (
    <SelectBox
      defaultListboxOpen={defaultListboxOpen}
      selectedValue={i18n.language}
      items={LANGUAGE_ITEMS}
      onChange={handleSelectedLanguage}
    />
  )
}

export default LanguageSelectOrganizer
