import useUserLanguage from '@components/hooks/useUserLanguage'
import NoticeScreen from '@components/molecules/NoticeScreen'
import KakaoLogin from '@components/pages/KakaoLogin'
import TermsPage from '@components/pages/TermsPage'
import React, {lazy, Suspense} from 'react'
import {useTranslation} from 'react-i18next'
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import AuthRoutes from './AuthRoutes'

const HomePage = lazy(() => import('@components/pages/HomePage'))
const LoginPage = lazy(() => import('@components/pages/LoginPage'))
const AccountPage = lazy(() => import('@components/pages/AccountPage'))
const MainPage = lazy(() => import('@components/pages/MainPage'))

const MessageListPage = lazy(() => import('@components/pages/MessageListPage'))

const MessageDetailPage = lazy(
  () => import('@components/pages/MessageDetailPage'),
)

const MessageInputPage = lazy(
  () => import('@components/pages/MessageInputPage'),
)

const SettingPage = lazy(() => import('@components/pages/SettingPage'))

const Routers = () => {
  const {t} = useTranslation()

  useUserLanguage()

  return (
    <Router>
      <Suspense
        fallback={
          <NoticeScreen
            bottomText={t('common.loading')}
            textColor="#fff"
            sprite={true}
          />
        }>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route
            path="/account"
            element={
              <AuthRoutes>
                <AccountPage />
              </AuthRoutes>
            }
          />
          <Route path="/main/:board" element={<MainPage />} />
          <Route path="/main/list/:board" element={<MessageListPage />} />
          <Route
            path="/main/list/detail/:index"
            element={<MessageDetailPage />}
          />
          <Route path="/faq/:board" element={<SettingPage />} />
          <Route
            path="/faq/rename/:board"
            element={
              <AuthRoutes>
                <AccountPage />
              </AuthRoutes>
            }
          />
          <Route path="/main/input/:board" element={<MessageInputPage />} />
          <Route path="/faq/terms" element={<TermsPage />} />
          <Route path="/oauth/callback/kakao" element={<KakaoLogin />} />
        </Routes>
      </Suspense>
    </Router>
  )
}

export default Routers
