import {IMAGES} from '@assets/images/image'
import IconButton from '@components/atoms/IconButton'
import PublicText from '@components/atoms/PublicText'
import LanguageSelectOrganizer from '@components/organizers/LanguageSelectOrganizer'
import React from 'react'
import styled from 'styled-components'
import {match} from 'ts-pattern'

export interface HeaderBoxProps {
  left?: LeftType
  right?: RightType
  headerTitle?: string | boolean
  textSize?: number
}

type IconButtonType = {
  type: 'iconButton'
  icon?: string
  isHide?: boolean
  onClick?: () => void
}

type LeftType =
  | {
      type: 'languageSelect'
      defaultListboxOpen?: boolean
    }
  | IconButtonType

type RightType = IconButtonType

const HeaderBox = ({left, right, headerTitle, textSize}: HeaderBoxProps) => {
  return (
    <Container>
      {/* 왼쪽 영역 */}
      {match(left)
        .with({type: 'languageSelect'}, ({defaultListboxOpen}) => (
          <LanguageSelectOrganizer defaultListboxOpen={defaultListboxOpen} />
        ))
        .with({type: 'iconButton'}, ({icon, onClick}) => (
          <IconButton
            size={32}
            source={icon ? icon : IMAGES.backIcon}
            onClick={onClick}
          />
        ))
        .otherwise(() => (
          <IconButton hidden={true} size={32} />
        ))}

      <PublicText textColor="#FFF" textSize={textSize ? textSize : 24}>
        {headerTitle ? headerTitle : ''}
      </PublicText>

      {/* 오른쪽 영역 */}
      {match(right)
        .with({type: 'iconButton'}, ({icon, onClick}) => (
          <IconButton
            size={32}
            source={icon ? icon : IMAGES.closeIconWhite}
            onClick={onClick}
          />
        ))
        .otherwise(() => (
          <IconButton hidden={true} size={32} />
        ))}
    </Container>
  )
}

export default HeaderBox

const Container = styled.div`
  background-color: #000180;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 44px;
  margin: 5px 0;
  padding: 0 6px;
`
